@import '../colors.css';

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-color);
  padding: 5px;
  height: 140px; /* Adjust as needed */
}

.header-logo {
  max-height: 100%;
  max-width: 900px; /* Adjust width as needed */
  object-fit: contain;
}
